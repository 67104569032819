<template>
  <m-invoices
    :load="load"
    :reset-items="() => {$store.dispatch('entities/invoices/deleteAll')}"
    height="calc(100vh - 378px)"
  />
</template>

<script>

export default {
  methods: {
    async load (filters) {
      return this.$store.dispatch('entities/invoices/loadMemberInvoices', {
        organisation: this.$route.params.organisation,
        username: this.$route.params.username,
        params: filters
      })
    }
  }
}
</script>
