<template>
  <q-page>
    <div class="row">
      <div
        v-if="resource"
        class="member-info col-sm-12"
      >
        <div class="top-sheet bg-primary row">
          <div class="col-sm-10">
            <q-btn
              v-if="canViewOrg"
              size="lg"
              color="white"
              dense
              round
              flat
              class="back"
              icon="chevron_left"
              @click="$router.push(`/organisations/${resource.organisation.slug}/members`)"
            >
              <q-tooltip>
                {{ $t('back_to_organisation') }}
              </q-tooltip>
            </q-btn>
            <m-image
              v-else
              :src="resource.picture"
              class="avatar"
            />
            <h4 class="text-white">
              {{ resource.display_name }}
              <h6>{{ resource.is_blocked ? $t('blocked') : $t('active') }} {{ displayRole }}</h6>
            </h4>
            <q-btn
              v-if="canChangeRole"
              class="text-white change-role"
              size="sm"
              :label="$t('change_role')"
              @click="changeRoleModal(resource.roles)"
            />
            <div class="user-meta text-white">
              <p>{{ resource.email }}</p>
              <p>{{ $t('member_since', { date: resource.created_at.date | date }) }}</p>
            </div>
          </div>
          <div class="col-sm-2">
            <m-user-meta-actions :member="resource" />
          </div>
        </div>
        <q-tabs
          align="justify"
          class="shadow-2 bg-primary-tint text-white"
        >
          <q-route-tab

            to="bookings"
            name="bookings"
            icon="receipt"
          >
            {{ $tc('booking.booking', 2) }}
          </q-route-tab>
          <q-route-tab

            to="journeys"
            name="journeys"
            icon="map"
          >
            {{ $tc('journey.journey', 2) }}
          </q-route-tab>
          <q-route-tab

            to="invoices"
            name="invoices"
            icon="payment"
          >
            {{ $tc('invoice.invoice', 2) }}
          </q-route-tab>
          <q-route-tab
            v-if="canViewNote"

            to="notes"
            name="notes"
            icon="note"
          >
            {{ $t('notes') }}
          </q-route-tab>
          <q-route-tab

            to="history"
            name="history"
            icon="history"
          >
            {{ $t('history') }}
          </q-route-tab>
        </q-tabs>
        <router-view
          :key="$route.fullPath"
          :member="$store.getters['members/getUser'](this.$route.params.username)"
        />
      </div>
    </div>
  </q-page>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'
import store from 'store'
import date from 'utils/date-time'

export default {
  name: 'MemberView',
  filters: {
    date (data) {
      return date.toShortDate(data)
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    }),
    canViewOrg () {
      return this.$store.getters.roles.includes('admin') || this.$store.getters.roles.includes('backoffice')
    },
    canChangeRole () {
      return this.$store.getters.roles.includes('admin') || this.$store.getters.roles.includes('backoffice') ||
        (this.$store.getters.roles.includes('middleoffice') && this.user.username !== this.resource.username)
    },
    canViewNote () {
      return this.$store.getters.hasPermission('notes.index')
    },
    resource () {
      return this.$store.getters['members/getUser'](this.$route.params.username)
    },
    displayRole () {
      return this.resource.roles.includes('middleoffice') ? 'middleoffice' : 'member'
    }
  },
  beforeRouteEnter (to, from, next) {
    const org = to.params.organisation
    return store.dispatch('members/loadUser', {
      orgId: org,
      username: to.params.username,
      params: {
        include: 'organisation,roles'
      }
    })
      .then(() => next())
      .catch(e => { console.log(e) })
  },
  methods: {
    changeRoleModal (roles) {
      const roleToBecome = roles.includes('middleoffice') ? 'member' : 'middleoffice'
      const string = 'Change role to ' + roleToBecome
      this.$q.dialog({
        title: string,
        cancel: true,
        color: 'secondary'
      }).onOk(data => {
        this.$store.dispatch('members/changeRole', {
          org: this.resource.organisation.slug,
          user: this.$route.params.username,
          role: roleToBecome
        })
          .then(role => {
            this.$q.notify({
              message: this.$t('role_changed_to', { role }),
              color: 'positive',
              icon: 'mdi-check'
            })
          })
          .catch(e => {
            this.$q.notify({
              message: this.$t('error.role_not_changed'),
              color: 'negative',
              icon: 'clear'
            })
          })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.avatar
  margin-right 15px
h4, h6
  display inline-block
  vertical-align middle
h6
  text-transform uppercase
.user-meta
  margin-left 50px
  line-height 1.5
.member-info-list
  width 30%
  position relative
  z-index 200
.top-sheet
  padding 1.6rem 2rem
  padding-bottom 0
  position relative
  z-index 150
  min-height 135px
.q-tabs
  position relative
  z-index 100
.tab-body
  height calc(100vh - 233px)
  overflow auto
.change-role
  margin-left 10px
</style>
