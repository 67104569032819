<template>
  <div class="member-list">
    <m-resource-top-sheet
      :columns="columns"
      :title="$tc('member', 2)"
    >
      <template slot="tools">
        <div class="row tools q-col-gutter-sm">
          <div class="col-sm-4 gt-sm">
            <q-select
              v-model="filters.blocked"
              :options="block_statuses"
              :label="$t('status')"
              map-options
              emit-value
            />
          </div>
          <div class="col-xs-12 col-sm-8">
            <q-input
              v-model="searchTerm"
              :label="$t('search_for_member')"
              class="search"
              debounce="500"
              color="primary"
              @input="runSearch"
            />
          </div>
        </div>
        <q-btn
          fab
          class="absolute btn-create-member"
          color="positive"
          icon="add"
          style="right: 50px; top: 175px;"
          @click="createModal = true"
        />
        <member-create
          v-if="!individuals"
          v-model="createModal"
          :organisation="organisation"
          @created="onCreate"
          @switch-to-upload="switchToUpload"
        />
        <member-upload
          v-if="!individuals"
          v-model="uploadModal"
          :organisation="organisation"
          @created="onCreate"
        />
      </template>
    </m-resource-top-sheet>

    <q-scroll-area class="index-list">
      <m-infinite-scroll
        :load="filter"
        :page="filters.page"
        :pagination="pagination"
        icon="person"
        resource="members"
      >
        <div
          v-for="member in members"
          :key="member.username"
          class="row-item"
        >
          <member-card
            :member="member"
            :selected="selected"
          />
        </div>
      </m-infinite-scroll>
    </q-scroll-area>
  </div>
</template>

<script type="text/javascript">
import _ from 'lodash'
import store from 'store'
import memberCreate from './member-create'
import memberCard from './member-card'
import memberUpload from './member-upload'
import authentication from 'mixins/authentication'

export default {
  name: 'Members',
  components: {
    memberCreate,
    memberCard,
    memberUpload
  },
  mixins: [authentication],
  props: {
    small: {
      type: Boolean
    },
    selected: {
      type: String
    }
  },
  data () {
    return {
      searchTerm: '',
      createModal: false,
      uploadModal: false,
      members: [],
      block_statuses: [],
      filters: {
        page: 0,
        limit: 10,
        search: '',
        blocked: false
      },
      pagination: null,
      columns: [this.$t('name.full'), this.$t('email'), this.$t('status')]
    }
  },
  computed: {
    individuals () {
      return this.hasRole(['admin', 'backoffice']) && !this.$route.params.slug
    }
  },
  watch: {
    'filters.blocked' () {
      this.clear()
    }
  },
  created () {
    store.dispatch('members/reset')
    this.organisation = this.individuals
      ? null
      : this.$route.params.slug || this.$store.getters.organisation.slug

    this.block_statuses = this.$store.getters['members/getSelect']('blockStatuses')
  },
  methods: {
    clear () {
      this.filters.page = 0
      this.members = []
    },
    runSearch (search, done) {
      if (search.length || (!search.length && this.filters.search.length)) {
        this.clear()
        this.filters.search = search
      }
      done()
    },
    filter (page) {
      this.filters.include = 'organisation,roles'
      this.filters.page = page
      return store.dispatch('members/loadUsers', {
        orgId: this.organisation,
        params: _.pickBy(this.filters, val => val !== null && val !== '')
      })
        .then(response => {
          this.members = this.members.concat(response.data)
          this.pagination = response.meta.pagination
        })
    },
    onCreate () {
      this.clear()
    },
    switchToUpload () {
      this.createModal = false
      this.uploadModal = true
    }
  }
}
</script>

<style lang="stylus" scoped>
.index-list
  height calc(100vh - 279px)
  @media (min-width 768px)
    padding 0 3rem
    height calc(100vh - 271px)
  .row-item:first-child
    margin-top 16px

.col-head
  display: flex;
  justify-content: space-between;
  padding-right 40px

.tools
  width 50%

.index-head, .member
  @media (min-width 1024px)
    width 75%!important

.row-item
  width 100%

.member
  max-height 82px
  position relative
  //transition background-color .5s

.search
  margin-bottom 10px
  @media (min-width: 768px)
    margin-bottom 0px

.btn-create-member
  @media (max-width 768px)
    top 75px!important

</style>
